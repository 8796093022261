import get from "lodash/get";
import moment from "moment";

export const getAllMedia = (videos, photos, vimeo) => {
  const photosByCategory = photos.edges.map((pic) => {
    return {
      ...pic.node,
      tags: getMediaTag(pic),
      type: "photo",
      date: moment(get(pic, "node.date")),
    };
  });
  const videosByCategory = videos.edges.map((vid) => {
    return {
      ...vid.node,
      tags: getMediaTag(vid),
      type: "video",
      date: moment(get(vid, "node.date")),
    };
  });
  const vimeoByCategory = vimeo.edges.map((vid) => {
    return {
      ...vid.node,
      tags: getMediaTag(vid),
      type: "video",
      date: moment(get(vid, "node.date")),
    };
  });

  // aggregate photos and videos into a single array
  const media = [];
  Object.keys(videosByCategory).map((i) => media.push(videosByCategory[i]));
  Object.keys(photosByCategory).map((i) => media.push(photosByCategory[i]));
  Object.keys(vimeoByCategory).map((i) => media.push(vimeoByCategory[i]));
  //sort by date
  media.sort((a, b) => a.date.isAfter(b.date));
  return media;
};

export const slugify = (string) => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const manifest = {
  lab: {
    slug: "lab",
    objKey: "lab",
    title: "Environmental Services Lab",
    order: 2,
  },
  boisdarclake: {
    slug: "bois-darc-lake",
    objKey: "boisdarclake",
    title: "Bois d'Arc Lake",
    order: 6,
  },
  solidwaste: {
    slug: "solid-waste",
    objKey: "solidwaste",
    title: "Solid Waste",
    order: 3,
  },
  wastewater: {
    slug: "wastewater",
    objKey: "wastewater",
    title: "Wastewater",
    order: 4,
  },
  water: {
    slug: "water",
    objKey: "water",
    title: "Water",
    order: 5,
  },
  whoweare: {
    slug: "who-we-are",
    objKey: "whoweare",
    title: "Who We Are",
    order: 1,
  },
  waterquality: {
    slug: "water-quality",
    objKey: "waterquality",
    title: "Water Quality",
    order: 7,
  },
  waterconservation: {
    slug: "water-conservation",
    objKey: "waterconservation",
    title: "Water Conservation",
    order: 8,
  },
  watershed: {
    slug: "watershed",
    objKey: "watershed",
    title: "Watershed",
    order: 9,
  },
  wetlands: {
    slug: "wetlands",
    objKey: "wetlands",
    title: "Wetlands",
    order: 10,
  },
  // Alias because it looks like they've tagged some wrong
  wetland: {
    slug: "wetlands",
    objKey: "wetlands",
    title: "Wetlands",
    order: 10,
  },
  general: {
    slug: "general",
    objKey: "general",
    title: "General",
    order: 11,
  },
};

export const getMediaTag = (media, type) => {
  return getHashtags(get(media, "node"));
};

const getHashtags = (media) => {
  const nullDesc = get(media, "description") === null;
  const desc = nullDesc ? "" : get(media, "description", "");
  // extract # + chars up to white space
  const hashes = desc.match(/#\w+/g);
  const hasHash = get(hashes, "length");
  const tags = [];
  if (hasHash) {
    hashes.forEach((hash) => {
      const key = hasHash ? slugify(hash.slice(1, hash.length)) : null;
      const tag = manifest[key];
      if (tag) tags.push(tag);
    });
  }
  return get(tags, "length") ? tags : [manifest["general"]];
};
